<template>
  <div class="stats-page pb-3">
    <div class="controls-row row mb-4">
      <div class="col stats-page__select-range">
        <CustomSelect
          type="radiogroup"
          :disabled="isLoading"
          :options="customPeriodOptions"
          :selected.sync="selectedRange"
        />
      </div>
    </div>
    <Pane v-if="statData.length">
      <div class="d-flex justify-content-between stats-summary">
        <Loader :visible="isLoading" />
        <div v-for="stat in summaryStatsConfig" :key="stat.slug">
          <span class="stats-summary__big-number">
            {{ formatNumberCompact(totals[stat.slug]) }}{{ stat.suffix }}
          </span>
          <span class="stats-summary__label">
            {{ stat.label }}
          </span>
        </div>
      </div>
    </Pane>
    <div class="mt-3">
      <Pane v-if="(statChart && statChart[0].data.length) || isChartLoading">
        <Loader :visible="isChartLoading" />
        <div class="chart-controls">
          <CustomSelect
            type="select"
            :options="statsChartOptions"
            :selected.sync="statsConfig.metric"
            v-on:update:selected="loadStatChart()"
          />
        </div>
        <apexchart
          type="line"
          height="180"
          :options="chartOptions(findMin(statChart), findMax(statChart))"
          :series="statChart"
        />
      </Pane>
      <Pane v-if="!(statChart && statChart[0].data.length) && !isChartLoading">
        <div class="stats-page--empty" v-if="statChart">
          {{ $t("common.noDataAvailable") }}
        </div>
      </Pane>
    </div>
    <Pane class="mt-3">
      <Loader :visible="isLoading" />
      <DataGrid
        :data="statData"
        :model="incomeModel"
        expansible
        v-on:update:col="
          statsConfig.group = $event.value.group || $event.value.field;
          loadStat();
        "
        :detailedRequestConfig="detailedRequestConfig"
      />
    </Pane>
  </div>
</template>

<script>
import CustomSelect from '@/components/controls/CustomSelect.vue';
import DataGrid from '@/components/decorative/DataGrid.vue';
import Loader from '@/components/decorative/Loader.vue';
import Pane from '@/components/decorative/Pane.vue';
import chartOptions from '@/configs/chart-options/earningsChart';
import customPeriodOptions from '@/configs/customPeriodOptions';
import summaryStatsConfig from '@/configs/summaryStatsConfig';
import incomeModel from '@/configs/table-models/stats';
import detailedModels from '@/configs/table-models/statsDetailed';
import moment from 'moment';
import formatNumberCompact from '@/utils/numberFormat';


export default {
  name: 'Stats',
  mixins: [customPeriodOptions, incomeModel, detailedModels, summaryStatsConfig],
  components: {
    Pane,
    DataGrid,
    CustomSelect,
    Loader,
  },
  data() {
    return {
      selectedRange: null,
      selectedChart: null,
      statsConfig: {
        group: null,
        metric: null,
      },
      isLoading: false,
      isChartLoading: false,
    };
  },
  watch: {
    selectedRange() {
      if (this.getSelectedRange !== this.selectedRange) {
        this.$store
          .dispatch('setSelectedRange', this.selectedRange)
          .then(() => {
            this.loadStat();
          });
      }
    },
    statsConfig() {
      this.loadStatChart();
    },
  },
  computed: {
    statsChartOptions() {
      return [
        {
          id: 'bids',
          value: 'bids',
          label: this.$t('main.credits'),
        },
        {
          id: 'completed',
          value: 'completed',
          label: this.$t('main.inspections'),
          suffix: '%',
        },
        {
          id: 'ctr',
          value: 'ctr',
          label: 'CTR',
          suffix: '%',
        },
        {
          id: 'efficiency',
          value: 'efficiency',
          label: this.$t('common.utilization'),
          suffix: '%',
        },
        {
          id: 'loads',
          value: 'loads',
          label: this.$t('common.downloads'),
        },
        {
          id: 'visibility',
          value: 'visibility',
          label: this.$t('common.visibility'),
          suffix: '%',
        },
      ];
    },
    detailedRequestConfig() {
      const { start, end, group } = {
        ...this.selectedRange.value,
        ...this.statsConfig,
      };
      return {
        url: 'statistics/',
        start,
        end,
        group,
        detailedModels: this.detailedModels,
      };
    },
    getChartMetric() {
      const metric = this.$store.getters.getStatisticsChartMetric;
      return metric
        ? this.statsChartOptions.find(el => el.value === metric)
        : null;
    },
    getSelectedRange() {
      return this.$store.getters.getSelectedRange;
    },
    totals() {
      return this.$store.getters.getStatistics.length
        ? this.$store.getters.getStatistics[this.$store.getters.getStatistics.length - 1]
        : {};
    },
    statChart() {
      const metric = this.$store.getters.getStatisticsChartMetric;
      let data = [];

      if (Array.isArray(this.$store.getters.getStatisticsChart)) {
        data = this.$store.getters.getStatisticsChart.reduce((acc, cur) => {
          acc.push({
            y: cur[metric],
            x: moment(cur.ts).format('L LT'),
          });

          return acc;
        }, []);
      }

      const name = this.getChartMetric?.label || this.selectedChart.label;

      return [{ name, data }];
    },
    statData() {
      return Array.isArray(this.$store.getters.getStatistics)
        ? this.$store.getters.getStatistics.slice(0, -1)
        : [];
    },
  },
  created() {
    const [defaultPeriod] = this.customPeriodOptions;
    const [complexFirstOption] = this.incomeModel.complex.options;
    const [firstOption] = this.statsChartOptions;
    this.selectedRange = defaultPeriod;
    this.selectedChart = firstOption;
    this.statsConfig.metric = firstOption;
    this.statsConfig.group = complexFirstOption.value.group;
  },
  mounted() {
    if (this.getChartMetric) this.statsConfig.metric = this.getChartMetric;

    if (this.getSelectedRange && this.getSelectedRange.shouldReload) {
      this.selectedRange = this.getSelectedRange;
      this.loadStat();
      this.selectedRange.shouldReload = false;
    } else if (
      this.$store.getters.getStatisticsGroup !== this.incomeModel.complex.options[0].value.group
    ) {
      if (this.getSelectedRange) this.selectedRange = this.getSelectedRange;
      this.loadStat(true);
    }

    if (!this.getSelectedRange) {
      this.loadStat();
      this.$store.dispatch('setSelectedRange', this.selectedRange);
    } else {
      this.selectedRange = this.getSelectedRange;
    }
  },
  methods: {
    formatNumberCompact,
    findMin(data) {
      try {
        return data.reduce((acc, cur) => {
          const temp = cur.data.reduce(
            (a, c) => (a < c.y ? a : c.y),
            cur.data[0].y,
          );
          return temp < acc ? temp : acc;
        }, data[0].data[0].y);
      } catch {
        return 0;
      }
    },
    findMax(data) {
      try {
        return data.reduce((acc, cur) => {
          const temp = cur.data.reduce(
            (a, c) => (a > c.y ? a : c.y),
            cur.data[0].y,
          );
          return temp > acc ? temp : acc;
        }, 0);
      } catch {
        return 0;
      }
    },
    chartOptions,
    loadStatChart() {
      const { start, end, metric } = {
        ...this.selectedRange.value,
        ...this.statsConfig,
      };
      this.isChartLoading = true;
      this.$store
        .dispatch('statsChartRequest', {
          queryParams: { start, end, metric: metric.value },
        })
        .catch(() => {})
        .finally(() => {
          this.isChartLoading = false;
        });
    },
    loadStat(withoutChart) {
      this.isLoading = true;

      const { start, end, group } = {
        ...this.selectedRange.value,
        ...this.statsConfig,
      };

      this.$store
        .dispatch('statsRequest', {
          queryParams: { start, end, group },
        })
        .then(() => {
          this.$store.dispatch('setSelectedRange', this.selectedRange);
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
        });

      if (!withoutChart) this.loadStatChart();
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.chart-controls {
  position: absolute;
  top: 30px;
  left: 40px;
}

.stats {
  &-page {
    &__select-range {
      text-align: right;
    }

    &--empty {
      padding: 40px;
      color: $c-text-dimmed;
      text-align: center;
      text-transform: uppercase;
    }
  }
  &-summary {
    &__big-number {
      display: block;
      color: $c-text-primary;
      font-size: 1.6rem;
      line-height: 1em;
    }

    &__label {
      display: block;
      padding-top: 5px;
      color: $c-text-dimmed;
    }
  }
}
</style>
