export default {
  computed: {
    summaryStatsConfig() {
      return [
        {
          slug: 'bids',
          label: this.$t('main.credits'),
        },
        {
          slug: 'completed',
          label: this.$t('main.inspections'),
          suffix: '%',
        },
        {
          slug: 'ctr',
          label: 'CTR',
          suffix: '%',
        },
        {
          slug: 'efficiency',
          label: this.$t('common.utilization'),
          suffix: '%',
        },
        {
          slug: 'loads',
          label: this.$t('common.downloads'),
        },
        {
          slug: 'visibility',
          label: this.$t('common.visibility'),
          suffix: '%',
        },
      ];
    },
  },
};
